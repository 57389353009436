import React from "react";
import ActionIconButton from "./ActionIconButton";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SortingButtons = ({ ...props }) => {
	
	return (<>
		<ActionIconButton
			icontooltip={"Move " + props.itemtype + " up."}
			customclasses="ms-2 me-2"
			onClick={(e) => {
				e.stopPropagation();
				props.handleSorting(props.index, "up", props.item, props.itemlength) 
			}}
			icon={
				<KeyboardArrowUpIcon
					className={`${(props.index == 0) && "color-secondary"} ${props.fontsize}`}
				/>
			}
			disabled={true}
			issubmitting={(props.index == 0) ? "disabled" : ""}
		/>
		<ActionIconButton
			icontooltip={"Move " + props.itemtype + " down."}
			customclasses="ms-2 me-2"
			onClick={(e) => {
				e.stopPropagation();
				props.handleSorting(props.index, "down", props.item, props.itemlength) 
			}}
			icon={
				<KeyboardArrowDownIcon
					className={`${(props.index == (props.itemlength - 1)) && "color-secondary"} ${props.fontsize}`}
				/>
			}
			disabled={true}
			issubmitting={(props.index == (props.itemlength - 1)) ? "disabled" : ""}
		/>
	</>)
}

export default SortingButtons;